/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react'
import { AccordionOpen, Title } from '@/components/shared'
import { fetchData } from '@/utils/fetchData';
import webStore from '@/stores/web.store';


const FrecuentAskedQuestions = () => {
  const { isLoading, setLoading } = webStore();
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/web/faqs`);
        setDataFilter(data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <>
      {!isLoading && Object.values(dataFilter).length > 0 && (
        <section>
          <Title
            type="normal"
            title={''}
            subtitle={dataFilter?.titulo}
            description={dataFilter?.descripcion}
          />
          <AccordionOpen data={dataFilter} />
        </section>
      )}
    </>
  )
}

export default FrecuentAskedQuestions