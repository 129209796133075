import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2098387352/src/xana-website/src/app/(web)/(home)/_components/AboutUs.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2098387352/src/xana-website/src/app/(web)/(home)/_components/Banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2098387352/src/xana-website/src/app/(web)/(home)/_components/BannerHero.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2098387352/src/xana-website/src/app/(web)/(home)/_components/ContactUs.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2098387352/src/xana-website/src/app/(web)/(home)/_components/FrecuentAskedQuestions.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2098387352/src/xana-website/src/app/(web)/(home)/_components/LastNews.jsx");
