/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Element as Scroll } from "react-scroll";
import { Button, NewsMasonry, Title } from '@/components/shared'
import { fetchData } from '@/utils/fetchData';
import webStore from '@/stores/web.store';

const LastNews = () => {
  const { isLoading, setLoading } = webStore();
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/web/blog`);
        setDataFilter(data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);


  return (
    <>
      {!isLoading && Object.values(dataFilter).length > 0 && (
        <section className='pb-16'>
          <Scroll name={'blog'}>
            <Title
              type="normal"
              title={''}
              subtitle={dataFilter?.titulo}
              description={dataFilter?.descripcion}
            />
            <NewsMasonry data={dataFilter?.blogs} />

            <Link href={'/blog'} passHref >
              <div className={`w-full mx-auto mt-10`}>
                <Button
                  cssClass={`!justify-center`}
                  type={'link_news'}
                  title={'Ver todos los artículos'}
                  icon={'<svg fill=\"currentColor\" viewBox=\"0 0 16 16\" > <path fillRule=\"evenodd\" d=\"M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z\" /> </svg>'}
                />
              </div>
            </Link>
          </Scroll>
        </section>
      )}
    </>
  )
}

export default LastNews