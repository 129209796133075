/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react'
import { Hero } from '@/components/shared';
import { fetchData } from '@/utils/fetchData';
import webStore from '@/stores/web.store';

const BannerHero = () => {
  const { setLoading, isLoading } = webStore();
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/web/hero`);
        setDataFilter(data?.data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <>
      {!isLoading && Object.values(dataFilter).length > 0 && (
        <Hero type={'left_center'} data={dataFilter} />
      )}
    </>
  )
}

export default BannerHero