/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react'
import { Element as Scroll } from "react-scroll";
import { ContentLeftRight, Title } from '@/components/shared';
import { fetchData } from '@/utils/fetchData';
import webStore from '@/stores/web.store';

const AboutUs = () => {
  const { isLoading, setLoading } = webStore();
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/web/aboutus`);
        setDataFilter(data?.data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <>
      {!isLoading && Object.values(dataFilter).length > 0 && (
        <section className='pb-16'>
          <Scroll name={'quienes-somos'}>
            <Title
              type="normal"
              title={''}
              subtitle={dataFilter?.titulo}
              description={dataFilter?.descripcion}
            />
            <ContentLeftRight data={dataFilter?.columna} />
          </Scroll>
        </section >
      )}
    </>
  )
}

export default AboutUs