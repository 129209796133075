/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useState, useEffect, useCallback } from 'react'
import { Element as Scroll } from "react-scroll";
import { Tabs, Title } from '@/components/shared';
import useScreenResolution from '@/hooks/useScreenResolution';
import webStore from '@/stores/web.store';
import { fetchData } from '@/utils/fetchData';

const ContactUs = () => {
  const { isLoading, setLoading } = webStore();
  const { deviceType } = useScreenResolution();
  const [dataFetch, setDataFetch] = useState([]);
  const [filterData, setFilterData] = useState([])
  const [activeTab, setActiveTab] = useState(null);
  const [showTab, setShowTab] = useState(true);

  const handleOnchangeSelect = useCallback((event) => {
    setActiveTab(event.target.value);
    setShowTab(false);
  }, []);

  const handleTab = useCallback((tab) => {
    setActiveTab(tab.nombre);
    setShowTab(true);
  }, []);


  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/web/contactus`);
        setDataFetch(data?.data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);

  useEffect(() => {
    Object.values(dataFetch).length > 0 && setFilterData(dataFetch?.regions.filter(item => item.farmacias.length > 0))
  }, [dataFetch]);


  useEffect(() => {
    filterData && filterData.length > 0 && setActiveTab(deviceType === 'mobile' ? '' : filterData[0]?.nombre);
  }, [filterData]);



  const MobileTabs = () => {
    return (
      <>
        {filterData && filterData.length > 0 && filterData.map((item, idx) => (
          <option key={item.id} value={item.nombre}>
            {item.nombre}
          </option>
        ))}
      </>
    )
  }

  return (
    <>
      {!isLoading && Object.values(dataFetch).length > 0 && (
        <section className='pb-16'>
          <Scroll name={'ubicanos'}>
            <Title
              type="normal"
              subtitle={dataFetch?.titulo}
              description={dataFetch?.descripcion}
            />
            {/* MOBILE */}
            {(deviceType === 'mobile') && (
              <div className={`flex flex-col space-y-4 px-1}`}>
                <select id="state" name="state" className={`form-select h-full p-[8px] w-full shadow-sm rounded-lg border-2 focus:ring-2 focus:ring-xana-button-border border-gray-300 bg-transparent text-gray-400 placeholder:text-slate-300 text-base focus:border-transparent appearance-none`} value={activeTab || ''} onChange={handleOnchangeSelect}>
                  <option value="">Seleccione un estado</option>
                  {<MobileTabs />}
                </select>
                {activeTab && !showTab && (
                  <Tabs data={filterData} showTabs={showTab} setActiveSelected={''} activeSelect={activeTab} />
                )}
              </div>
            )}

            {/* DESKTOP */}
            {(deviceType === 'desktop' || deviceType === 'tablet' || deviceType === 'smarttv' || deviceType === undefined) && (
              <div className={`flex w-full`}>
                {showTab && (
                  <Tabs data={filterData} showTabs={showTab} setActiveSelected={handleTab} activeSelect={activeTab} />
                )}
              </div>
            )}
          </Scroll>
        </section>
      )}
    </>
  );
};

export default ContactUs;
