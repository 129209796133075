/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { useEffect, useState } from 'react'
import { CTA } from '@/components/shared';
import { fetchData } from '@/utils/fetchData';
import webStore from '@/stores/web.store';


const Banner = () => {
  const { isLoading, setLoading } = webStore();
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        const data = await fetchData(`${process.env.NEXT_PUBLIC_API_ROUTE}/web/banner`);
        setDataFilter(data?.data || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);


  return (
    <>
      {!isLoading && Object.values(dataFilter).length > 0 && (
        <section className='pb-16'>
          <CTA data={dataFilter} section={'text'} />
        </section>
      )}
    </>
  )
}

export default Banner